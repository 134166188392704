import { color } from 'hooks/Utils/color'
import React from 'react'

const ConditionsGenerales = () => {



  return (
    <div className='bg-gray-100 h-screen overflow-y-auto overflow-x-hidden'>
      <div className='p-5 bg-orange-400 text-center text-white '>
        <h1 className='font-black text-2xl mb-3'>
          Conditions Générales d'Utilisation du Reseau Social privé « CI-DIGIT »
        </h1>
        <p className='mt-3'>
          Dernière mise à jour : 13/12/2024
        </p>
      </div>

      <div className='m-0 lg:m-10 p-10 bg-white'>
        <p className='mt-3'>
          <b style={{ fontSize: '18px' }}>MYOPINION SARL</b>, une société de droit ivoirien, développe des systèmes et solutions à la pointe de la technologie. CI-DIGIT, un réseau social sécurisé et privé conçu pour les organisations, les entreprises et les groupes afin de favoriser la communication interne, la collaboration et l'engagement de leurs membres. Il offre un environnement contrôlé garantissant la confidentialité et la protection des données personnelles de ses utilisateurs.
        </p>

        <p className='my-3'>
          Le Gouvernement de côte d'ivoire « La société », ayant obtenu de MyOpinion le droit d'utiliser son invention, met en place les présentes Conditions pour la régulation et l'utilisation de « CI-DIGIT », des fonctionnalités, applications, services et technologies proposés, sauf s'il est expressément indiqué que des conditions distinctes (et non celles-ci) s'appliquent.
        </p>

        <p className='my-3'>
          Vous devez lire attentivement les présentes Conditions Générales d'Utilisation (CGU) avant de créer un compte pour bénéficier des services proposés. La Société se réserve le droit de modifier, à tout moment et sans préavis, les présentes conditions. La création d'un compte sur « CI-DIGIT » implique l'acceptation pleine et entière des présentes conditions sans aucune restriction. Les présentes CGU sont soumises aux dispositions de la loi n°2014-546 du 30 juillet 2013 relative aux transactions électroniques, du code des droits et obligations et des actes uniformes OHADA applicables à ces relations.
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 3 : Utilisation de la Plateforme</h4>
          L'accès à certaines informations diffusées sur l'application est subordonné à la création d'un Compte. L'Utilisateur n'a pas le droit de diffuser ou de reproduire, à titre gratuit ou onéreux, les informations auxquelles il a accès. L'Utilisateur n'est pas autorisé à copier ou transférer les informations diffusées sur quelque support que ce soit. L'utilisateur s'engage à ne pas porter atteinte aux informations, images ou marques contenues dans l'application. L'Utilisateur s'engage à respecter les présentes CGU et les autres normes en vigueur sur l’application.
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 4 : Accès à l'application et aux services</h4>
          L'accès à l'application se fait par la création d'un compte. Les membres sont titulaires d'un compte. Vous vous engagez à utiliser un mot de passe fort et à le garder confidentiel et à ne pas transférer une ou plusieurs parties de votre compte. L'utilisateur est responsable de la protection de son compte et nous déclinons toute responsabilité pour toute perte ou dommage qui pourrait en résulter. L'Utilisateur pourra accéder à l'application, 24 heures sur 24, 7 jours sur 7, sauf cas de force majeure, et/ou événement extérieur au centre serveur et sous réserve d'éventuels dysfonctionnements du Service. L'utilisateur assume tous les risques et contraintes liés à toute utilisation de l'application. La Société exclut toute garantie expresse ou implicite, notamment toute garantie implicite de qualité, d'adéquation à un usage particulier. L'utilisateur est responsable de la protection de son compte et nous déclinons toute responsabilité pour toute perte ou dommage qui pourrait en résulter. La création d'un Compte Utilisateur est nécessaire afin de permettre l'identification de l'Utilisateur et de faciliter son accès aux différents
          <br></br>
          services de l'application. Lors de cette étape, l'Utilisateur doit prendre connaissance des présentes CGU et les valider afin de finaliser la création de son compte.
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 5 : Règles d'utilisation</h4>
          CI-DIGIT est destiné à un usage professionnel uniquement. Les utilisateurs doivent respecter toutes les lois et réglementations applicables lorsqu'ils utilisent CI-DIGIT. Vous êtes tenus de :
          <br></br>
          - Respectez la confidentialité des informations sensibles de l'administration publique.
          <br></br>
          - Utilisez CI-DIGIT uniquement à des fins professionnelles liées à vos responsabilités au sein de la DGTPCP.
          <br></br>
          - Soyez courtois et respectueux dans toutes vos interactions sur la plateforme.
          <br></br>
          - Ne publiez pas de contenu diffamatoire, offensant ou discriminatoire.
          <br></br>
          - Assurez-vous que les informations partagées sont exactes et vérifiées avant leur publication.
          <br></br>
          - Signalez tout comportement inapproprié ou toute violation des règles à l'administrateur de CI-DIGIT.
          <br></br>
          - Gardez à l'esprit que l'utilisation de CI-DIGIT est soumise aux lois et réglementations en vigueur.
          <br></br>
          - Évitez de divulguer des informations confidentielles ou des données sensibles sur la plateforme.
          <br></br>
          - Conformez-vous aux décisions de la DGTPCP concernant l'utilisation de CI-DIGIT, y compris les suspensions ou les résiliations de compte.
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 6 : Responsabilité</h4>
          <p className='mt-3'>
            La responsabilité de Le Gouvernement de côte d'ivoire vis-à-vis des contenus publiés par les utilisateurs sur CI-DIGIT est un point essentiel à clarifier. La DGTPCP décline toute responsabilité concernant ces contenus. En effet, les informations partagées sur la plateforme relèvent de la responsabilité individuelle des utilisateurs. Bien que la DGTPCP mette en place des mesures de contrôle et de modération, elle ne peut garantir l'exactitude, l'intégrité ou la légalité de chaque contenu publié.
          </p>
          <p className='mt-3'>
            Les utilisateurs sont donc tenus de vérifier l'exactitude des informations qu'ils partagent sur CI-DIGIT. Ils doivent s'assurer que les données et les documents partagés sont précis, conformes aux politiques internes de l'administration publique et aux lois en vigueur. De plus, les utilisateurs sont responsables de leurs propres actions et comportements sur la plateforme. Cela inclut le respect des règles de conduite, la conformité aux politiques de sécurité et la prévention de tout comportement inapproprié.
          </p>
          <p className='mt-3'>
            En déclinant toute responsabilité quant aux contenus publiés par les utilisateurs, la DGTPCP cherche à encourager une utilisation responsable et éthique de CI-DIGIT. Il est donc impératif pour chaque utilisateur de prendre conscience de sa responsabilité individuelle dans la gestion et la diffusion d'informations sur la plateforme, contribuant ainsi à maintenir un
            environnement professionnel, sûr et respectueux au sein de l'administration publique.
          </p>
          <p className='mt-3'>
            La Société ne pourra être tenue responsable en cas de poursuites judiciaires à l'encontre de l'Utilisateur et/ou de ses éventuels préposés, du fait de l'utilisation des données collectées sur l'application.
          </p>
          La société n'est pas responsable de toute utilisation d'informations insultantes, illégales, trompeuses ou inappropriées que vous, ou un autre utilisateur ou un tiers créez, téléchargez, publiez, envoyez, recevez ou stockez sur ou via nos services. L'Utilisateur est responsable vis-à-vis de la Société et/ou des tiers de tout dommage, direct ou indirect, de quelque nature que ce soit, causé par lui-même et/ou par ses éventuels préposés lors de l'utilisation de l'application par ses soins.
          <p className='mt-3'>
            La Société décline toute responsabilité en cas d'utilisation des informations obtenu sur l'application en violation des règles spécifiées dans les présentes Conditions d'utilisation.
          </p>
        </p>


        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 7 : Confidentialité</h4>
          <p className='mt-3'>
            Dans le cadre de l'utilisation de CI-DIGIT, il est impératif de respecter scrupuleusement la confidentialité des informations et de la propriété intellectuelle du Le Gouvernement de côte d'ivoire. Les utilisateurs sont tenus de ne pas divulguer, partager ou discuter de manière inappropriée des informations sensibles ou exclusives sur l'application.
          </p>
          <p className='mt-3'>
            Il est primordial de prendre toutes les mesures nécessaires pour protéger la confidentialité des données personnelles partagées sur CI-DIGIT. Les utilisateurs doivent respecter les lois et réglementations applicables en matière de protection des données et s'assurer que tout échange d'informations est conforme aux normes de confidentialité établies par la DGTPCP.
          </p>
          <p className='mt-3'>
            Toute violation de la confidentialité, que ce soit par la divulgation non autorisée d'informations sensibles, le partage inapproprié de données personnelles ou toute autre action compromettant la confidentialité des informations, peut entraîner des conséquences graves, y compris des sanctions disciplinaires.
          </p>
          <p className='mt-3'>
            En participant à CI-DIGIT, chaque utilisateur s'engage à être vigilant quant à la protection de la confidentialité des informations échangées et à respecter les règles et les politiques de sécurité établies par la DGTPCP. La confidentialité est un élément essentiel de la confiance et de l'intégrité de notre réseau social d'entreprise, et chaque utilisateur a la responsabilité de la préserver dans toutes ses interactions sur CI-DIGIT.
          </p>
        </p>


        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 8 : Droit d'accès aux données personnelles Réclamations</h4>
          <p className='mt-3'>
            Les informations demandées et collectées à votre sujet sont nécessaires et obligatoires pour créer votre compte utilisateur et pour faciliter l'identification et la reconnaissance de l'Utilisateur. Conformément à la loi n°2013-450 du 19 juin 2013 relative à la protection des données personnelles, l'Utilisateur dispose d'un droit d'accès, de rectification, de modification et de suppression des données le concernant. Pour exercer les droits ci-dessus, l'Utilisateur utilisera les onglets correspondants de l'application. De manière générale, toute réclamation doit être formulée par écrit et envoyée à l'adresse email suivante :
          </p>
          <p className='mt-3'>
            <b style={{ color: 'red' }}> « cidigit.ci »</b>, La communication des données de l'Utilisateur et la validation des présentes CGU autorisent la Société à utiliser lesdites données jusqu'à ce que l'Utilisateur en décide autrement.
          </p>
        </p>


        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9 : Standards communautaires « CI-DIGIT »</h4>
          <p className='mt-3'>
            CI-DIGIT est bien plus qu'une simple plateforme de réseau social au sein de l'administration publique. Chaque jour, les utilisateurs s'engagent à partager leurs expériences, à se connecter avec leurs collègues et à construire des communautés de travail dynamiques et collaboratives. Reconnaissant l'importance cruciale de CI-DIGIT dans la communication et la collaboration au sein de l'administration publique, Le Gouvernement de côte d'ivoire a établi des normes communautaires pour réguler les interactions sur la plateforme.
          </p>
          <p className='mt-3'>
            CI-DIGIT offre un espace où les membres de la communauté peuvent s'exprimer librement dans les limites fixées par les règles et les valeurs de l'administration publique. Les normes communautaires sont conçues pour promouvoir un environnement de communication fluide et pour encourager la libre expression des idées novatrices qui contribuent au bon fonctionnement de l'administration.
          </p>
          <p className='mt-3'>
            En intégrant le puissant moteur de traitement de langage naturel et de création de contenu GPT-4, CI-DIGIT vise à transformer la manière dont les employés interagissent, collaborent et prospèrent au sein de l'administration publique. Cependant, pour maintenir un environnement sain et respectueux, certaines règles doivent être respectées.
          </p>
          <p className='mt-3'>
            Les normes communautaires de CI-DIGIT visent à :
          </p>
          <p className='mt-3'>
            Favoriser une communication constructive : CI-DIGIT encourage les échanges d'idées et la discussion ouverte, tout en promouvant le respect mutuel entre les membres de la communauté.
          </p>
          <p className='mt-3'>
            Améliorer la productivité : CI-DIGIT vise à faciliter la collaboration et à accroître l'efficacité des processus de travail au sein de l'administration publique.
          </p>
          <p className='mt-3'>
            Assurer la fluidité des informations : CI-DIGIT sert de plateforme pour partager des informations pertinentes et importantes entre les différents services de l'administration publique, favorisant ainsi une meilleure coordination et une prise de décision éclairée.
          </p>
          <p className='mt-3'>
            Encourager le libre-échange : CI-DIGIT permet aux employés de partager leurs points de vue et leurs opinions, même s'ils sont en désaccord, dans le respect des valeurs et des politiques de l'administration publique.
          </p>
          <p className='mt-3'>
            Respecter les normes internationales des droits de l'homme : En cas de contenu potentiellement controversé, CI-DIGIT se réfère aux normes internationales des droits de l'homme pour évaluer son acceptabilité, en pesant la valeur de l'intérêt public par rapport au risque de préjudice.
          </p>
          <p className='mt-3'>
            En adhérant à ces normes communautaires, les utilisateurs de CI-DIGIT contribuent à créer un environnement en ligne professionnel, respectueux et propice à l'échange d'idées et à la collaboration au sein de l'administration publique.
          </p>
        </p>


        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9.1 : Comportement professionnel</h4>
          <p className='mt-3'>
            Adoptez un comportement professionnel à tout moment. Traitez les autres membres avec respect et courtoisie, comme vous le feriez sur un lieu de travail physique
          </p>
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9.2 : Confidentialité</h4>
          <p className='mt-3'>
            Protégez les informations sensibles et confidentielles de l'entreprise. Ne partagez pas de données propriétaires ou de secrets commerciaux sur CI-DIGIT.
          </p>
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9.3 : Pas de publicité</h4>
          <p className='mt-3'>
            N'utilisez pas CI-DIGIT à des fins d'autopromotion, de publicité ou de sollicitation sans l'accord préalable des administrateurs de l'entreprise.
          </p>
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9.4 : Intimidation et harcèlement</h4>
          <p className='mt-3'>
            L'intimidation et le harcèlement se produisent dans de nombreux endroits et prennent de nombreuses formes différentes, allant des menaces et de la divulgation d'informations personnellement identifiables à l'envoi de messages menaçants et aux contacts malveillants non désirés. Nous ne tolérons pas ce genre de comportement car cela empêche les gens de se sentir en sécurité et respectés sur « Trésor Conect ».
          </p>

          <p className='mt-3'>
            Notre protection est très vaste concernant les atteintes énumérées nous supprimons les contenus destinés à dégrader ou à faire honte, y compris, par exemple, les allégations concernant l'activité sexuelle personnelle d'une personne.
          </p>

          <p className='mt-3'>
            Nous reconnaissons que l'intimidation et le harcèlement peuvent avoir un impact émotionnel
          </p>
          <p className='mt-3'>
            Le contexte et l'intention sont importants, et nous autorisons les gens à publier et à partager s'il est clair que quelque chose a été partagé afin de condamner ou d'attirer l'attention sur intimidation et harcèlement. Dans certains cas, nous exigeons l'autodéclaration, car cela nous aide à comprendre que la personne ciblée se sent victime d'intimidation ou de harcèlement. En plus de signaler de tels comportements et contenus, nous encourageons les gens à utiliser tous les outils légaux pour s'en protéger.
          </p>
          <p className='mt-3'>
            Ne pas :<br></br>
            <b>Niveau 1 : Cibler quelqu'un de manière malveillante en :</b><br></br>
            <br></br>
            -  Contacter quelqu'un à plusieurs reprises d'une manière qui est :
            <br></br>
            - Indésirable, ou
            <br></br>
            - Harcèlement sexuel, ou
            <br></br>
            <br></br>
            - Destiné à un grand nombre de personnes sans sollicitation préalable.
            <br></br>
            -  Attaquer quelqu'un en raison de son statut de victime d'agression sexuelle, d'exploitation sexuelle, de harcèlement sexuel ou de violence domestique.
            <br></br>
            -  Appel à l'automutilation ou au suicide d'une personne spécifique ou d'un groupe de personnes.
            <br></br>
            -  Attaquer quelqu'un par des termes péjoratifs liés à l'activité sexuelle (par exemple : putain, salope).
            <br></br>
            - Publier du contenu sur une tragédie violente ou sur des victimes de tragédies violentes qui incluent des affirmations selon lesquelles une tragédie violente ne s'est pas produite.
            <br></br>
            -  Publier du contenu sur les victimes ou les survivants de tragédies violentes ou d'attentats terroristes par leur nom ou par leur image, en prétendant qu'ils sont :
            <br></br>
            -
            Créer des pages ou des groupes dédiés à attaquer des individus en :
          </p>

          <p className='mt-3'>
            Appeler à la mort, ou contracter ou développer une condition médicale.
            <br></br>
            - Faire des déclarations d'intention de préconiser de s'engager dans une activité sexuelle.
            <br></br>
            - Affirmer que la personne a ou pourrait avoir une maladie sexuellement transmissible.
            <br></br>
            - Sexualiser un autre adulte.
          </p>

          <p className='mt-3'>
            Envoi de messages contenant les attaques suivantes lorsqu'ils sont dirigés contre un individu ou un groupe d'individus dans le fil :
          </p>
          <p className='mt-3'>
            - Attaques référencées dans les niveaux 1, 2 et 4 de cette politique.
            <br></br>
            -  Injures ciblées.
            <br></br>
            -  Appels à la mort, à une maladie grave, à un handicap, à une maladie épidémique ou à un préjudice physique.
          </p>
          <b>Niveau 2 : Ciblez des employé(e)s, des personnalités de l’entreprise à portée limitée (par exemple, des personnes dont la notoriété principale se limite à leur activisme, leur journalisme ou celles qui sont devenues célèbres par des moyens involontaires)</b>

          <p className='mt-3'>
            -  Appels à la mort, ou à contracter ou développer une condition médicale. Termes de malédiction de sexe féminin lorsqu'ils sont utilisés de manière péjorative.
            <br></br>
            -  Allégations concernant l'activité sexuelle ou les maladies sexuellement transmissibles, sauf dans le contexte d'allégations criminelles contre des adultes concernant des attouchements sexuels non consensuels.
            <br></br>
            -  Pages ou groupes créés pour attaquer via des injures ciblées, descriptions physiques négatives.
            <br></br>
            -  Revendications d'identité religieuse ou de blasphème.
            <br></br>
            -  Expressions de mépris ou de dégoût.
            <br></br>
            -  Termes de malédiction de sexe féminin lorsqu'ils sont utilisés de manière péjorative.
          </p>

          <p className='mt-3'>
            <b>Niveau 3 : Cibler les personnalités de l’entreprise en les exposant délibérément à :</b>
            <br></br>
            -  Appels à la mort, ou à contracter ou développer une condition médicale.
            <br></br>
            -  Allégations sur les maladies sexuellement transmissibles Termes de malédiction de sexe féminin lorsqu'ils sont utilisés de manière péjorative.
            <br></br>
            -  Contenu qui loue, célèbre ou se moque de leur mort ou de leur état de santé.
            <br></br>
            -  Attaques à travers des descriptions physiques négatives. Comparaisons avec des animaux ou des insectes culturellement perçus comme intellectuellement ou physiquement inférieurs ou avec un objet inanimé (« vache », « singe », « pomme de terre »).
            <br></br>
            -  Contenu manipulé pour mettre en évidence, encercler ou attirer l'attention de manière négative sur des caractéristiques physiques spécifiques (nez, oreille, etc.).
          </p>
          <p className='mt-3'>
            <b>Niveau 4 : Ciblez des employé(é)s ou des personnalités de l’entreprise à portée limitée (par exemple, des personnes dont la notoriété principale se limite à leur activisme, leur journalisme ou celles qui sont devenues célèbres par des moyens involontaires) avec
              :</b>
            <br></br>
            -  Comparaisons avec des animaux ou des insectes culturellement perçus comme intellectuellement ou physiquement inférieurs ou avec un objet inanimé (« vache », « singe », « pomme de terre »).
            <br></br>
            -  Contenu manipulé pour mettre en évidence, encercler ou attirer l'attention de manière négative sur des caractéristiques physiques spécifiques (nez, oreille, etc.).
            <br></br>
            -  Attaques à travers des descriptions physiques négatives. Contenu qui classe les individus en fonction de leur apparence physique ou de leur personnalité.
            <br></br>
            -  Contenu sexualisant un autre adulte.
            <br></br>
            -  Contenu qui dégrade davantage les personnes qui sont décrites comme étant victimes d'intimidation physique, sauf dans des contextes d'autodéfense et de sport de combat.
            <br></br>
            -  Contenu qui loue, célèbre ou se moque de leur mort ou de blessures physiques graves.
          </p>



          <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
            <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9.5 : Nudité adulte et activité sexuelle</h4>
            <p className='mt-3'>
              Nous restreignons l'affichage de la nudité ou de l'activité sexuelle car l’objectif du réseau est centré sur la performance de l’entreprise. De plus, nous supprimons les images sexuelles pour empêcher le partage de contenu non consensuel ou mineur. Les restrictions sur l'affichage d'activités sexuelles s'appliquent également au contenu créé numériquement, sauf s'il est publié à des fins éducatives, humoristiques ou satiriques.
            </p>
            <p className='mt-3'>
              Nos politiques en matière de nudité sont devenues plus nuancées au fil du temps. Nous comprenons que la nudité peut être partagée pour diverses raisons, notamment comme forme de protestation, pour sensibiliser à une cause, ou pour des raisons éducatives ou médicales. Lorsque cette intention est claire, nous tenons compte du contenu. Par exemple, bien que nous restreignions certaines images de seins féminins incluant le mamelon, nous autorisons d'autres images, y compris celles représentant des actes de protestation, des femmes qui allaitent activement et des photos de cicatrices post-mastectomie. Pour les images représentant des organes génitaux visibles ou l'anus dans le contexte des moments de naissance et d'après- naissance ou de situations liées à la santé, nous incluons une étiquette
              d'avertissement afin que les gens sachent que le contenu peut être sensible. Nous autorisons également les photographies de peintures, de sculptures et d'autres œuvres d'art représentant des personnages nus. Ne postez pas : Images de vrais adultes nus, si elles représentent : Organes génitaux visibles, sauf dans le contexte de l'accouchement et des moments après l'accouchement ou dans des situations liées à la santé (par exemple, chirurgie de confirmation du sexe, examen pour le cancer ou prévention/évaluation de la maladie). Anus visible et/ou gros plans de fesses entièrement nus, sauf si photoshoppé sur une personnalité publique. Mamelons féminins découverts sauf dans le contexte de l'allaitement, de l'accouchement et des moments après l'accouchement, des situations liées à la santé (par exemple, post- mastectomie, sensibilisation au cancer du sein ou chirurgie de confirmation du genre) ou un acte de protestation. Images d'activité sexuelle, y compris :
            </p>
            <p className='mt-3'>
              <br></br>
              -  Activité sexuelle explicite et stimulation
              <br></br>
              -  Rapport sexuel explicite ou sexe oral, défini comme la bouche ou les organes génitaux entrant ou en contact avec les organes génitaux ou l'anus d'une autre personne, où au moins les organes génitaux d'une personne sont nus.
              <br></br>
              -  Stimulation explicite des organes génitaux ou de l'anus, définie comme la stimulation des organes génitaux ou de l'anus ou l'insertion d'objets, y compris des jouets sexuels, dans les organes génitaux ou l'anus, où les organes génitaux et le contact avec les organes génitaux sont directement visibles.
              <br></br>
              -  Activité sexuelle et stimulation implicites Rapports sexuels implicites ou rapports sexuels oraux, définis comme la bouche ou les organes génitaux entrant ou en contact avec les organes génitaux ou l'anus d'une autre personne, lorsque les organes génitaux ne sont pas directement visibles, sauf dans les cas d'un contexte de santé sexuelle, de publicités et d'images fictives reconnues ou avec des indicateurs de fiction.
              <br></br>
              -  Stimulation implicite des organes génitaux ou de l'anus, définie comme la stimulation des organes génitaux ou de l'anus ou l'insertion d'objets, y compris des jouets sexuels, dans ou au-dessus des organes génitaux ou de l'anus, lorsque l'activité ou le contact n'est pas directement visible, sauf en cas de contexte de santé sexuelle, de publicités et de images fictives ou avec des indices de fiction.
              <br></br>
              -  Autres activités, y compris, mais sans s'y limiter :
              <br></br>
              -  Érections Présence de sous-produits de l'activité sexuelle. Jouets sexuels placés ou insérés dans la bouche. Stimulation des mamelons humains nus.
              <br></br>
              -  Presser les seins féminins, défini comme un mouvement de saisie avec des doigts courbés qui montre à la fois des marques et un changement de forme clair des seins.
            </p>

            <p className='mt-3'>
              Nous autorisons la compression dans les contextes d'allaitement. Contenu fétichiste qui implique :
              <br></br>
              -   Actes susceptibles d'entraîner la mort d'une personne ou d'un animal.
              <br></br>
              -  Démembrement. Cannibalisme.
              <br></br>
              -  Excréments, urine, crachats, morve, menstruation ou vomi.
              <br></br>
              -  Bestialité.
            </p>

            <p className='mt-3'>
              Contenu numérique qui répond à notre définition de l'activité sexuelle, sauf lorsqu'il est publié dans un contexte éducatif ou scientifique, ou lorsqu'il répond à l'un des critères ci-dessous et qu'il est affiché uniquement aux personnes de 18 ans et plus. Audio étendu de l'activité sexuelle Nous ne montrons ce contenu qu'aux personnes de 18 ans et plus :
              <br></br>
              -  Art du monde réel qui représente l'activité sexuelle.
              <br></br>
              -  Publier des photos ou des vidéos d'objets illustrant une activité sexuelle dans l'art du monde réel.
              <br></br>
              -  Imagerie représentant la bestialité dans l'art du monde réel à condition qu'elle soit partagée de manière neutre ou dans la condamnation et que les personnes ou les animaux représentés ne soient pas réels.
              <br></br>
              -  Activité sexuelle implicite dans les publicités.
              <br></br>
              -  Activité sexuelle implicite dans des images fictives reconnues ou avec des indicateurs de fiction.
            </p>
          </p>


          <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
            <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 10 : Violation des règles</h4>
            <p className='mt-3'>
              Si vous êtes témoin d'une violation de ces règles ou si vous soupçonnez un comportement inapproprié, signalez-le à nos canaux de signalement désignés.
            </p>
            <p className='mt-3'>
              Le non-respect de ces règles peut entraîner une suspension temporaire ou un retrait permanent de CI-DIGIT, selon la décision de la direction de l'entreprise.
            </p>
          </p>


          <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
            <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 11 : Mises à jour et modifications</h4>
            <p className='mt-3'>
              La direction Générale du trésor se réserve le droit de modifier les présentes conditions d'utilisation à tout moment. Les utilisateurs seront informés de tout changement par le biais d'une notification sur le RSE. En continuant à utiliser après de telles modifications, les utilisateurs acceptent les conditions mises à jour.
            </p>
          </p>

          <p style={{ fontWeight: '600', marginTop: '20px' }}>
            En utilisant « CI-DIGIT », vous reconnaissez avoir lu, compris et accepté de respecter ces règles et conditions d'utilisation de la communauté. Nous nous engageons à fournir un espace en ligne sûr, collaboratif et productif pour nos employés et partenaires. Votre coopération au maintien de cet environnement est grandement appréciée.
          </p>

        </p>

      </div>
    </div>
  )

}

export default ConditionsGenerales