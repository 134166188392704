import { color } from 'hooks/Utils/color'
import React from 'react'

const TermsConditions = () => {
  return (
    <div className='bg-gray-100 h-screen overflow-y-auto overflow-x-hidden'>
      <div className='p-5 bg-orange-400 text-center text-white '>
        <h1 className='font-black text-2xl mb-3'>
          General Conditions of Use of the Private Social Network “CI-DIGIT”
        </h1>
        <p className='mt-3'>
          Last updated: 13/12/2024
        </p>
      </div>

      <div className='m-0 lg:m-10 p-10 bg-white'>
        <p className='mt-3'>
          <b style={{ fontSize: '18px' }}>MYOPINION SARL</b>, a company under Ivorian law, develops cutting-edge systems and solutions. CI-DIGIT, a secure and private social network designed for organizations, companies and groups to promote internal communication, collaboration and engagement of their members. It offers a controlled environment guaranteeing the confidentiality and protection of its users' personal data.
        </p>

        <p className='my-3'>
          The Government of Côte d'Ivoire "The Company", having obtained from MyOpinion the right to use its invention, sets up these Conditions for the regulation and use of "CI-DIGIT", the functionalities, applications, services and technologies offered, unless it is expressly indicated that separate conditions (and not these) apply.
        </p>

        <p className='my-3'>
          You must read these General Terms of Use (GTU) carefully before creating an account to benefit from the services offered. The Company reserves the right to modify these conditions at any time and without notice. The creation of an account on "CI-DIGIT" implies full and complete acceptance of these conditions without any restriction. These GTU are subject to the provisions of Law No. 2014-546 of July 30, 2013 relating to electronic transactions, the Code of Rights and Obligations and the OHADA uniform acts applicable to these relationships.
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 3: Use of the Platform</h4>
          Access to certain information distributed on the application is subject to the creation of an Account. The User does not have the right to distribute or reproduce, free of charge or for a fee, the information to which he has access. The User is not authorized to copy or transfer the information distributed on any medium whatsoever. The user undertakes not to harm the information, images or brands contained in the application. The User undertakes to comply with these T&Cs and other standards in force on the application.
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 4: Access to the application and services</h4>
          Access to the application is done by creating an account. Members are account holders. You agree to use a strong password and keep it confidential and not to transfer one or more parts of your account. The user is responsible for protecting his account and we decline all responsibility for any loss or damage that may result. The User will be able to access the application, 24 hours a day, 7 days a week, except in cases of force majeure, and / or event external to the server center and subject to possible malfunctions of the Service. The user assumes all risks and constraints related to any use of the application. The Company excludes any express or implied warranty, in particular any implied warranty of quality, suitability for a particular use. The user is responsible for protecting his account and we decline all responsibility for any loss or damage that may result. The creation of a User Account is necessary in order to allow the identification of the User and to facilitate his access to the various
          <br></br>
          application services. During this step, the User must read these T&Cs and validate them in order to finalize the creation of their account.
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 5: Rules of use</h4>
          CI-DIGIT is intended for professional use only. Users must comply with all applicable laws and regulations when using CI-DIGIT. You are required to:
          <br></br>
          - Respect the confidentiality of sensitive public administration information.
          <br></br>
          - Use CI-DIGIT only for professional purposes related to your responsibilities within the DGTPCP.
          <br></br>
          - Be courteous and respectful in all your interactions on the platform.
          <br></br>
          - Do not publish defamatory, offensive or discriminatory content.
          <br></br>
          - Make sure that the information shared is accurate and verified before publication.
          <br></br>
          - Report any inappropriate behavior or violation of the rules to the CI-DIGIT administrator.
          <br></br>
          - Keep in mind that the use of CI-DIGIT is subject to applicable laws and regulations.
          <br></br>
          - Avoid disclosing confidential information or sensitive data on the platform. <br></br>
          - Comply with DGTPCP decisions regarding the use of CI-DIGIT, including account suspensions or terminations.
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 6: Responsibility</h4>
          <p className='mt-3'>
            The responsibility of the Government of Côte d'Ivoire for the content published by users on CI-DIGIT is an essential point to clarify. The DGTPCP declines all responsibility for this content. Indeed, the information shared on the platform is the individual responsibility of users. Although the DGTPCP implements control and moderation measures, it cannot guarantee the accuracy, integrity or legality of each content published.
          </p>
          <p className='mt-3'>
            Users are therefore required to verify the accuracy of the information they share on CI-DIGIT. They must ensure that the data and documents shared are accurate, in accordance with the internal policies of the public administration and the laws in force. In addition, users are responsible for their own actions and behavior on the platform. This includes respecting the rules of conduct, complying with security policies and preventing any inappropriate behavior.
          </p>
          <p className='mt-3'>
            By declining any responsibility for the content published by users, the DGTPCP seeks to encourage responsible and ethical use of CI-DIGIT. It is therefore imperative for each user to be aware of their individual responsibility in the management and dissemination of information on the platform, thus contributing to maintaining a professional, safe and respectful environment within the public administration.
          </p>
          <p className='mt-3'>
            The Company shall not be held liable in the event of legal proceedings against the User and/or his/her possible agents, due to the use of the data collected on the application.
          </p>
          The Company is not responsible for any use of insulting, illegal, misleading or inappropriate information that you, or another user or a third party creates, uploads, publishes, sends, receives or stores on or via our services. The User is liable to the Company and/or third parties for any damage, direct or indirect, of any nature whatsoever, caused by himself/herself and/or by his/her possible agents during the use of the application by him/her.
          <p className='mt-3'>
            The Company declines all liability in the event of use of the information obtained on the application in violation of the rules specified in these Terms of Use.
          </p>
        </p>


        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 7: Confidentiality</h4>
          <p className='mt-3'>
            When using CI-DIGIT, it is imperative to scrupulously respect the confidentiality of information and intellectual property of the Government of Côte d'Ivoire. Users are required not to disclose, share or discuss sensitive or proprietary information on the application in an inappropriate manner.
          </p>
          <p className='mt-3'>
            It is essential to take all necessary measures to protect the confidentiality of personal data shared on CI-DIGIT. Users must comply with applicable data protection laws and regulations and ensure that any exchange of information complies with the confidentiality standards established by the DGTPCP.
          </p>
          <p className='mt-3'>
            Any breach of confidentiality, whether through the unauthorized disclosure of sensitive information, the inappropriate sharing of personal data, or any other action compromising the confidentiality of information, may result in serious consequences, including disciplinary sanctions.
          </p>
          <p className='mt-3'>
            By participating in CI-DIGIT, each user agrees to be vigilant in protecting the confidentiality of the information exchanged and to comply with the rules and security policies established by the DGTPCP. Confidentiality is an essential element of the trust and integrity of our corporate social network, and each user is responsible for preserving it in all their interactions on CI-DIGIT.
          </p>
        </p>


        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 8: Right of access to personal data Complaints</h4>
          <p className='mt-3'>
            The information requested and collected about you is necessary and mandatory to create your user account and to facilitate the identification and recognition of the User. In accordance with Law No. 2013-450 of June 19, 2013 relating to the protection of personal data, the User has the right to access, rectify, modify and delete data concerning him/her. To exercise the above rights, the User will use the corresponding tabs of the application. In general, any complaint must be made in writing and sent to the following email address:
          </p>
          <p className='mt-3'>
            <b style={{ color: 'red' }}> "cidigit.ci"</b>, The communication of the User's data and the validation of these T&Cs authorize the Company to use said data until the User decides otherwise.
          </p>
        </p>


        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9: "CI-DIGIT" Community Standards</h4>
          <p className='mt-3'>
            CI-DIGIT is much more than just a social networking platform within the public administration. Every day, users engage in sharing their experiences, connecting with their colleagues and building dynamic and collaborative work communities. Recognizing the crucial importance of CI-DIGIT in communication and collaboration within the public administration, the Government of Côte d'Ivoire has established community standards to regulate interactions on the platform.
          </p>
          <p className='mt-3'>
            CI-DIGIT provides a space where community members can express themselves freely within the limits set by the rules and values ​​of public administration. Community standards are designed to promote a fluid communication environment and to encourage the free expression of innovative ideas that contribute to the smooth functioning of the administration.
          </p>
          <p className='mt-3'>
            By integrating the powerful GPT-4 natural language processing and content creation engine, CI-DIGIT aims to transform the way employees interact, collaborate and thrive within public administration. However, to maintain a healthy and respectful environment, certain rules must be respected.
          </p>
          <p className='mt-3'>
            CI-DIGIT Community Standards aim to:
          </p>
          <p className='mt-3'>
            Foster constructive communication: CI-DIGIT encourages the exchange of ideas and open discussion, while promoting mutual respect among community members.
          </p>
          <p className='mt-3'>
            Improve productivity: CI-DIGIT aims to facilitate collaboration and increase the efficiency of work processes within the public administration.
          </p>
          <p className='mt-3'>
            Ensure the fluidity of information: CI-DIGIT serves as a platform to share relevant and important information between the different departments of the public administration, thus promoting better coordination and informed decision-making.
          </p>
          <p className='mt-3'>
            Encourage free exchange: CI-DIGIT allows employees to share their views and opinions, even if they disagree, while respecting the values ​​and policies of the public administration.
          </p>
          <p className='mt-3'>
            Respect international human rights standards: In the case of potentially controversial content, CI-DIGIT refers to international human rights standards to assess its acceptability, weighing the value of the public interest against the risk of harm.
          </p>
          <p className='mt-3'>
            By adhering to these community standards, CI-DIGIT users contribute to creating a professional, respectful online environment that is conducive to the exchange of ideas and collaboration within the public administration.
          </p>
        </p>


        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9.1: Professional Conduct</h4>
          <p className='mt-3'>
            Maintain a professional conduct at all times. Treat other members with respect and courtesy, as you would in a physical workplace
          </p>
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9.2: Confidentiality</h4>
          <p className='mt-3'>
            Protect sensitive and confidential company information. Do not share proprietary data or trade secrets on CI-DIGIT.
          </p>
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9.3: No advertising</h4>
          <p className='mt-3'>
            Do not use CI-DIGIT for self-promotion, advertising or solicitation purposes without the prior consent of the company's administrators.
          </p>
        </p>

        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 9.4: Bullying and Harassment</h4>
          <p className='mt-3'>
            Bullying and harassment occur in many places and take many different forms, from threats and the disclosure of personally identifiable information to sending threatening messages and unwanted malicious contact. We do not tolerate this type of behavior because it prevents people from feeling safe and respected on "Trésor Conect".
          </p>

          <p className='mt-3'>
            Our protection is very broad regarding the listed violations we remove content intended to degrade or shame, including, for example, allegations about a person's personal sexual activity.
          </p>

          <p className='mt-3'>
            We recognize that bullying and harassment can have an emotional impact
          </p>
          <p className='mt-3'>
            Context and intent matter, and we allow people to post and share if it is clear that something was shared to condemn or draw attention to bullying and harassment. In some cases, we require self-reporting because it helps us understand that the targeted person feels they are being bullied or harassed. In addition to reporting such behavior and content, we encourage people to use all legal tools to protect themselves.
          </p>
          <p className='mt-3'>
            Do not:<br></br>
            <b>Level 1: Maliciously target someone by:</b><br></br>
            <br></br>
            - Repeatedly contacting someone in a way that is:
            <br></br>
            - Unwanted, or
            <br></br>
            - Sexually harassing, or
            <br></br>
            <br></br>
            - Intended for a large number of people without prior solicitation.
            <br></br>
            - Attacking someone because of their status as a victim of sexual assault, sexual exploitation, sexual harassment, or domestic violence.
            <br></br>
            - Calling a specific person or group of people to self-harm or commit suicide.
            <br></br>
            - Attacking someone with derogatory terms related to sexual activity (e.g., whore, slut).
            <br></br>
            - Posting content about a violent tragedy or victims of violent tragedies that includes claims that a violent tragedy did not occur.
            <br></br>
            - Posting content about victims or survivors of violent tragedies or terrorist attacks by name or image, claiming that they are:
            <br></br>
            -
            Creating pages or groups dedicated to attacking individuals by:
          </p>

          <p className='mt-3'>
            Calling for death, or contracting or developing a medical condition.
            <br></br>
            - Making statements of intent to advocate engaging in sexual activity.
            <br></br>
            - Claiming that the person has or may have a sexually transmitted disease.
            <br></br>
            - Sexualizing another adult.
          </p>

          <p className='mt-3'>
            Posting messages containing the following attacks when directed at an individual or group of individuals in the thread:
          </p>
          <p className='mt-3'>
            - Attacks referenced in Levels 1, 2, and 4 of this policy.
            <br></br>
            - Targeted name-calling.
            <br></br>
            - Calls for death, serious illness, disability, epidemic disease, or physical harm.
          </p>
          <b>Level 2: Target employees, limited-scope company figures (e.g., people whose primary notoriety is limited to their activism, journalism, or those who became famous through unintentional means)</b>

          <p className='mt-3'>
            - Calls for death, or to contract or develop a medical condition. Female curse words when used in a derogatory manner.
            <br></br>
            - Allegations about sexual activity or sexually transmitted diseases, except in the context of criminal allegations against adults involving nonconsensual sexual touching.
            <br></br>
            - Pages or groups created to attack via targeted slurs, negative physical descriptions.
            <br></br>
            - Claims of religious identity or blasphemy.
            <br></br>
            - Expressions of contempt or disgust.
            <br></br>
            - Female curse terms when used in a derogatory manner.
          </p>

          <p className='mt-3'>
            <b>Level 3: Targeting corporate figures by deliberately exposing them to:</b>
            <br></br>
            - Calls for death, or to contract or develop a medical condition.
            <br></br>
            - Claims about sexually transmitted diseases Female curse terms when used in a derogatory manner.
            <br></br>
            - Content that praises, celebrates, or mocks their death or health condition.
            <br></br>
            - Attacks through negative physical descriptions. Comparisons to animals or insects culturally perceived as intellectually or physically inferior or to an inanimate object ("cow", "monkey", "potato").
            <br></br>
            - Content manipulated to highlight, surround, or draw negative attention to specific physical features (nose, ear, etc.).
          </p>
          <p className='mt-3'>
            <b>Level 4: Target employees or company figures with limited reach (e.g., people whose primary fame is limited to their activism, journalism, or those who have become famous through unintentional means) with
              :</b>
            <br></br>
            - Comparisons to animals or insects culturally perceived as intellectually or physically inferior or to an inanimate object ("cow," "monkey," "potato").
            <br></br>
            - Content manipulated to highlight, frame, or draw negative attention to specific physical characteristics (nose, ear, etc.).
            <br></br>
            - Attacks through negative physical descriptions. Content that categorizes individuals based on their physical appearance or personality.
            <br></br>
            - Content that sexualizes another adult.
            <br></br>
            - Content that further degrades people who are depicted as being physically bullied, except in self-defense and combat sports contexts.
            <br></br>
            - Content that praises, celebrates, or mocks their death or serious physical injury.
          </p>


          <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
            <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Section 9.5: Adult Nudity and Sexual Activity</h4>
            <p className='mt-3'>
              We restrict the display of nudity or sexual activity because the network's purpose is focused on business performance. Additionally, we remove sexual images to prevent the sharing of non-consensual or underage content. Restrictions on the display of sexual activity also apply to digitally created content, unless it is posted for educational, humorous, or satirical purposes.
            </p>
            <p className='mt-3'>
              Our nudity policies have become more nuanced over time. We understand that nudity can be shared for a variety of reasons, including as a form of protest, to raise awareness for a cause, or for educational or medical reasons. When that intent is clear, we take the content into account. For example, while we restrict some images of female breasts that include the nipple, we allow other images, including those depicting protest, women actively breastfeeding, and photos of post-mastectomy scars. For images depicting visible genitals or the anus in the context of birth and post-birth moments or health-related situations, we include a warning label so people know the content may be sensitive. We also allow photographs of paintings, sculptures, and other artworks that depict nude figures. Do not post: Images of real, nude adults, if they depict: Visible genitals, except in the context of childbirth and postpartum, or health-related situations (e.g., gender confirmation surgery, cancer screening, or disease prevention/evaluation). Visible anus and/or close-ups of fully nude buttocks, unless photoshopped onto a public figure. Exposed female nipples, except in the context of breastfeeding, childbirth and postpartum, health-related situations (e.g., post-mastectomy, breast cancer awareness, or gender confirmation surgery), or an act of protest. Images of sexual activity, including:
            </p>
            <p className='mt-3'>
              <br></br>
              - Explicit sexual activity and stimulation
              <br></br>
              - Explicit sexual intercourse or oral sex, defined as the mouth or genitals entering or contacting the genitals or anus of another person, where at least one person's genitals are naked.
              <br></br>
              - Explicit stimulation of the genitals or anus, defined as stimulation of the genitals or anus or insertion of objects, including sex toys, into the genitals or anus, where the genitals and contact with the genitals are directly visible.
              <br></br>
              - Implied sexual activity and stimulation Implied sexual intercourse or oral sex, defined as the mouth or genitals entering or contacting the genitals or anus of another person, when the genitals are not directly visible, except in cases of sexual health context, advertisements and recognized fictional images or with indicators of fiction.
              <br></br>
              - Implied genital or anal stimulation, defined as stimulation of the genitals or anus or insertion of objects, including sex toys, into or over the genitals or anus, when the activity or contact is not directly visible, except in cases of sexual health context, advertisements and recognized fictional images or with indicators of fiction.
              <br></br>
              - Other activities, including but not limited to:
              <br></br>
              - Erections Presence of byproducts of sexual activity. Sex toys placed or inserted into the mouth. Stimulation of naked human nipples.
              <br></br>
              - Squeezing female breasts, defined as a grasping motion with curved fingers that shows both markings and a clear change in shape of the breasts.
            </p>

            <p className='mt-3'>
              We allow compression in breastfeeding contexts. Fetish content that involves:
              <br></br>
              - Acts that could result in the death of a person or animal.
              <br></br>
              - Dismemberment. Cannibalism.
              <br></br>
              - Feces, urine, spit, snot, menstruation, or vomit.
              <br></br>
              - Bestiality.
            </p>

            <p className='mt-3'>
              Digital content that meets our definition of sexual activity, except when it is posted in an educational or scientific context, or when it meets one of the criteria below and is only shown to people 18 years of age or older. Extended audio of sexual activity We only show this content to people 18 years of age or older:
              <br></br>
              - Real-world art that depicts sexual activity.
              <br></br>
              - Posting photos or videos of objects depicting sexual activity in real-world art.
              <br></br>
              - Imagery depicting bestiality in real-world art provided it is shared in a neutral or condemnatory manner and the people or animals depicted are not real.
              <br></br>
              - Implied sexual activity in advertisements.
              <br></br>
              - Implied sexual activity in recognized fictional images or with indicators of fiction.
            </p>
          </p>

          <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
            <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 10: Violation of Rules</h4>
            <p className='mt-3'>
              If you witness a violation of these rules or suspect inappropriate behavior, report it to our designated reporting channels.
            </p>
            <p className='mt-3'>
              Failure to comply with these rules may result in temporary suspension or permanent removal from CI-DIGIT, as determined by the company's management.
            </p>
          </p>


          <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
            <h4 style={{ fontWeight: '600', color: color.primary, marginTop: '10px' }}>Article 11: Updates and modifications</h4>
            <p className='mt-3'>
              The General Directorate of the Treasury reserves the right to modify these terms of use at any time. Users will be informed of any changes through a notification on the RSE. By continuing to use after such modifications, users accept the updated terms.
            </p>
          </p>

          <p style={{ fontWeight: '600', marginTop: '20px' }}>
            By using "CI-DIGIT", you acknowledge having read, understood and agree to comply with these rules and conditions of use of the community. We are committed to providing a safe, collaborative and productive online space for our employees and partners. Your cooperation in maintaining this environment is greatly appreciated.
          </p>
        </p>

      </div>
    </div>
  )
}

export default TermsConditions