import React from 'react'

const PolitiqueConfidentialite = () => {
  return (
    <div className='bg-gray-100 h-screen overflow-y-auto overflow-x-hidden'>
      <div className='p-5 bg-orange-400 text-center text-white '>
        <h1 className='font-black text-2xl mb-3'>
          Privacy Policy of « CI-DIGIT »
        </h1>
        <p className='mt-3'>
          Last updated: 13/12/2024
        </p>
      </div>
      <div className='m-0 lg:m-10 p-10 bg-white'>

        <div className="text-justify">
          <p>We believe that you should always know what information we collect from you and how we use it, and that you should have effective control over both. We want to give you the ability to make the best decisions about the information you share with us. That is the purpose of this Privacy Policy.</p>
          <ol>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Information Collection</span><br />
              <p>
                We collect information when you register on our site, log into your account, and/or log out. The information collected includes your name, email address, and/or phone number.
                Additionally, we automatically receive and record information from your computer and browser, including your IP address, your software and hardware, and the page you request.
              </p>
            </li>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Use of Information</span><br />
              <p>
                Any of the information we collect from you may be used to:
              </p><ul>
                <li>Personalize your experience and meet your individual needs</li>
                <li>Provide customized advertising content</li>
                <li>Improve our site</li>
                <li>Improve customer service and your support needs</li>
                <li>Contact you via email</li>
                <li>Administer a contest, promotion, or survey</li>
              </ul>
              <p />
            </li>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Privacy</span><br />
              <p>
                We are the sole owners of the information collected on this site. Your personal information will not be sold, exchanged, transferred, or given to any other company for any reason, without your consent, other than what is necessary to fulfill a request.
              </p>
            </li>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Third Party Disclosure</span><br />
              <p>
                We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website or conducting our business, as long as those parties agree to keep this information confidential.
              </p>
              <p>
                We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms of Use, or when required by law.
              </p>
              <p>Non-personal information, however, may be provided to other parties for marketing, advertising, or other uses.</p>
            </li>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Protecting Information</span><br />
              <p>
                We implement a variety of security measures to maintain the safety of your personal information. We use state-of-the-art encryption to protect sensitive information transmitted online. We also protect your information offline. Only employees who need to perform a specific job (for example, customer service) are granted access to personally identifiable information. Computers and servers used to store personally identifiable information are kept in a secure environment.
              </p>
            </li>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Use of Cookies</span><br />
              <p>
                Our cookies improve access to our site and identify repeat visitors. In addition, our cookies enhance the user experience by tracking and targeting their interests. However, this use of cookies is in no way linked to any personally identifiable information on our site.
              </p>
            </li>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Unsubscribe</span><br />
              <p>
                We use the email address that you provide to send you information and updates pertaining to your order, occasional company news, related product information, etc. If at any time you would like to unsubscribe from receiving future emails, detailed unsubscribe instructions are included at the bottom of each email.
              </p>
            </li>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Children and Our Services</span><br />
              <p>
                Our Services are not directed to children and you are prohibited from using our Services if you are under the age of 13. You must also be old enough to consent to the processing of your personal data in your country (in some countries, we may allow your parent or guardian to do so on your behalf).
              </p>
            </li>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Right of access to personal data - Complaints</span><br />
              <p>
                The information requested and collected about you is necessary and mandatory to create your user account and to facilitate the identification and recognition of the User. In accordance with Law No. 2013-450 of June 19, 2013 on the protection of personal data, the User has the right to access, rectify, modify and delete data concerning him. However, he may not under any circumstances modify the email address he has provided. To exercise the above rights, the User will use the relevant tabs of the application. In general, all complaints must be filed in writing and sent to the following email address: support@myopinion.ci. The communication of the User's data and the validation of these Terms of Use authorize the Company to use said data until the User decides otherwise.
              </p>
            </li>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Consent</span><br />
              <p>
                By using our site, you consent to our privacy policy.
              </p>
            </li>
            <li className='mt-3'>
              <span className="text-orange-500 font-bold">Changes to this Privacy Policy</span><br />
              <p>
                We reserve the right to change this Privacy Policy at any time. The latest version of this policy governs our processing of your personal data and will always be available on the dedicated tab. If we change this policy in a way that we consider, in our sole discretion, to be material, we will notify you by sending an email to the address associated with your account. By continuing to access or use the Services after those changes become effective, you agree to be bound by the terms set forth in the new Privacy Policy.
              </p>
            </li>
          </ol>
        </div>
      </div>

    </div>
  )
}

export default PolitiqueConfidentialite