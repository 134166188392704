import React, { memo, useEffect, useState } from 'react';
import './Topbar.style.css';
import { Link } from 'react-router-dom';
import { color } from '../../hooks/Utils/color';
import { MdNotificationsNone } from 'react-icons/md';
import { IoSearch } from 'react-icons/io5';
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import { Image } from 'react-bootstrap';
import { api } from '../../api/api';
import ProfilePicture from 'components/ProfilePicture/ProfilePicture';


const TopBar = () => {

  const { data: AuthData } = useAuthReducer()
  const accessToken = useAccessToken()
  const [logo, setLogo] = useState();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };


  /* GET NEW LOGO */
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const request = await api(`api/assets/header_logo`, 'GET', null, accessToken)
        const response = await request.json()
        // console.log('Response fetch logo:', response)
        if (request.ok && request.status === 200) {
          setLogo(response?.url)
        }
      } catch ({ message }) {
        fetchLogo()
        throw new Error(message)
      }
    }
    // fetchLogo()
  }, [])


  return (
    <div className="topbar">
      <div className="logo">
        <img src={require('assets/app/icon_header.png')} width={145} alt='' x/>
      </div>
      <nav>
        <ul style={{ display: 'flex', alignItems: 'center'}}>

          <li style={{ marginRight: '20px' }}>
            <Link to={'/search'}>
              <IoSearch size={30} color={'#808080'} />
            </Link>
          </li>

          <li style={{ marginRight: '20px' }}>
            <Link to={'/notification'}>
              <MdNotificationsNone size={30} color={'#808080'} />
            </Link>
          </li>

          <li style={{ marginRight: '20px' }}>
            <Link to={`/profiles/user/${AuthData?.user?.user_username}`}>
              {/* <Image
                alt=""
                src={AuthData?.user?.profile?.prof_picture}
                width="35"
                height="35"
                roundedCircle={true}
                className="d-inline-block align-top ms-2"
              /> */}

              <ProfilePicture
                user={AuthData?.user}
                uri={AuthData?.user?.profile?.prof_picture}
                size={35}
              />

            </Link>
          </li>

        </ul>
      </nav>
    </div>
  );
};

export default memo(TopBar);
