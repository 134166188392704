import { api } from 'api/api'
import Header from 'components/Header/Header'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import avatar from 'assets/images/background_opinion.jpg'
import { firestoreDB } from 'services/Firebase/firebase'
import { scheme } from 'constants/env'
import firestorekeys from 'constants/firestorekeys'
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap'

const GroupInvite = () => {

  const { t, i18n } = useTranslation()
  const { data: authContextState } = useAuthReducer()
  const navigate = useNavigate();
  const accessToken = useAccessToken();

  const location = useLocation();
  const {
    chat_id,
    conv_id,
    conv_name
  } = location?.state;

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [hasMorePage, setHasMorePage] = useState(false)

  const [isFetching, setIsFetching] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [searchText, setSearchText] = useState('')

  const goBack = () => navigate(-1)


  useEffect(() => {
    fetchSearchData()
  }, [])

  useEffect(() => {
    const searchTimerId = setTimeout(() => {
      fetchSearchData()
    }, 500);
    return () => {
      clearTimeout(searchTimerId);
    }
  }, [searchText])

  const fetchSearchData = async () => {
    try {
      if (!isFetching) {
        setIsFetching(true)
        const body = { search: searchText }
        const request = await api(
          `api/search/search_users?page=${1}`,
          'POST',
          body,
          accessToken
        )
        const response = await request?.json()
        setIsFetching(false)
        // console.log('Search person response:', response?.search_person?.data)
        if (request?.status === 200) {
          if (response?.success) {
            if (response?.search_person?.next_page_url) {
              setHasMorePage(true)
            } else {
              setHasMorePage(false)
            }
            setData(response?.search_person?.data || [])
            setPage(2)
          }
        }
      }
    } catch (error) {
      setIsFetching(false)
      console.error(error)
    }
  }


  const loadMoreSearchData = async () => {
    try {
      if (
        searchText?.length >= 3
        && hasMorePage
        && !isFetching
      ) {
        const body = { search: searchText }
        const request = await api(
          `api/search/search_users?page=${page}`,
          'POST',
          body,
          accessToken
        )
        const response = await request?.json()
        // console.log('Search person response:', response)
        if (request?.status === 200) {
          if (response?.success) {
            if (response?.search_person?.next_page_url) {
              setHasMorePage(true)
            } else {
              setHasMorePage(false)
            }
            setData(data => [...data, ...response?.search_person?.data || []])
            setPage(page => page + 1)
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }



  const handleSelectUser = (user) => {
    if (selectedUsers.some(item => item?.user_id == user?.user_id)) {
      setSelectedUsers(selectedUsers.filter(item => item?.user_id != user?.user_id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleSendInvite = () => {
    try {
      if (selectedUsers?.length !== 0) {
        selectedUsers?.forEach(user => addMember(user?.user_id));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const addMember = async (user_id) => {
    try {
      setIsLoading(true)
      const data = {
        user_id: user_id,
        role: 'P'
      }

      const request = await api(
        `api/conversations/${conv_id}/add_user`,
        'POST',
        data,
        accessToken
      )
      const response = await request.json()
      console.log('Response add member:', response)
      if (request.status === 200) {
        const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString());
        const docSnap = await getDoc(docRef);  // Use getDoc for a single document
        if (docSnap.exists()) {
          console.log('Conversation:', docSnap.data());
          saveMember(user_id, docSnap.data())
        } else {
          setIsLoading(false)
          console.log('No such document!');
        }
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error add member', error);
    }
  }


  const saveMember = async (user_id, conversation) => {
    try {
      const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString());
      await updateDoc(docRef, {
        requests: [...conversation?.requests || [], user_id],
        // participants: [...conversation?.participants || [], user_id]
      }).then(() => {
        console.log('Group chat saved to firstore.');
      }).catch((error) => {
        setIsLoading(false)
        console.error('Error save firstore group chat:', error);
      });

      navigate(-1)

    } catch (error) {
      setIsLoading(false)
      console.error('Error fetch group chat:', error);
    }
  }


  const renderLoader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '5%' }}>
        <div style={{ textAlign: 'center' }}>
          <Spinner
            size="sm"
            role="status"
            aria-hidden="true"
            animation="border"
            variant='secondary'
            style={{
              width: '25px',
              height: '25px'
            }}
          />
          <h6 style={{ color: '#808080', marginTop: '3%', }}>
            {t('loading')}
          </h6>
        </div>
      </div>
    )
  }

  return (
    <div className='h-screen bg-white'>
      <Header title='Demande de participation' goBack={goBack} className={'ps-2'} />
      <div className='h-full pt-3'>

        <div>
          <input
            type='search'
            placeholder={i18n.language == 'en'
              ? 'Search...'
              : 'Recherche...'
            }
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        {selectedUsers.length != 0 &&
          <div className='flex justify-between items-center p-3'>
            <h3 className='font-bold mb-2'>
              {i18n.language == 'en'
                ? 'Selected users'
                : 'Utilisateurs selectionnés'
              }
            </h3>

            {isLoading ?
              <div role="status" className='mx-4'>
                <svg aria-hidden="true" className="inline w-5 h-5 text-orange-400 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
              :
              <button onClick={handleSendInvite} className='py-2 px-3 rounded-full text-white text-sm font-bold bg-orange-400'>
                {t('invite')} ({selectedUsers?.length})
              </button>
            }
          </div>
        }
        <div className='flex overflow-x-auto w-11/12 px-3'>
          {selectedUsers.map((user, index) => (
            <div key={index} className='text-center me-3'>
              <img
                src={user?.profile?.prof_picture || avatar}
                alt=''
                style={{
                  width: '32px',
                  height: '32px',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                className='object-cover object-center rounded-full'
              />
              <div>
                <p className='text-xs font-bold p-0 m-0'>
                  {`${user?.user_surname} ${user?.user_name}`.length > 9
                    ? `${user?.user_surname} ${user?.user_name}`?.slice(0, 9) + '...'
                    : `${user?.user_surname} ${user?.user_name}`
                  }
                </p>
              </div>
              <button onClick={() => handleSelectUser(user)} className='flex justify-center mx-auto items-center text-red-600 hover:text-red-700 py-2 rounded-full'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
                {/* <span className='text-xs font-bold'>
                  {t('delete')}
                </span> */}
              </button>
            </div>
          ))}
        </div>

        <div style={{ height: selectedUsers?.length == 0 ? '95%' : '79%' }} className='mt-2'>
          <h3 className='font-bold px-3 mb-1'>
            {i18n.language == 'en'
              ? 'Users'
              : 'Utilisateurs'
            }
          </h3>
          <div id='scrollableDiv' style={{ height: '87%' }} className='pb-28 lg:pb-10'>
            <InfiniteScroll
              dataLength={data?.length}
              next={loadMoreSearchData}
              hasMore={hasMorePage}
              loader={renderLoader()}
              scrollableTarget="scrollableDiv"
              scrollThreshold={0.9}>
              {data?.map((user, index) => {
                return (
                  <div key={index} onClick={() => handleSelectUser(user)} className='flex items-center py-3 px-3 cursor-pointer hover:bg-gray-100'>
                    <input
                      type='radio'
                      checked={selectedUsers.includes(user)}
                      onChange={() => handleSelectUser(user)}
                      className='me-3'
                    />
                    <img src={user?.profile?.prof_picture || avatar} alt='' className='w-12 h-12 object-cover object-center rounded-full me-3' />
                    <div>
                      <p className='font-bold p-0 m-0'>
                        {user?.user_surname || ''} {user?.user_name || ''}
                      </p>
                      <span className='text-sm'>
                        @{user?.user_username}
                      </span>
                    </div>
                  </div>
                )
              })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GroupInvite