import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Login from '../modules/Authentication/Login/Login'
import Register from '../modules/Authentication/Register/Register'
import ConfirmPassword from '../modules/Authentication/ConfirmPassword/ConfirmPassword'
import ForgetPassword from '../modules/Authentication/ForgetPassword/ForgetPassword'

import PrivacyPolicy from '../modules/Legal/en/PrivacyPolicy'
import TermsConditions from '../modules/Legal/en/TermsConditions'
import PolitiqueConfidentialite from '../modules/Legal/fr/PolitiqueConfidentialite'
import ConditionsGenerales from '../modules/Legal/fr/ConditionsGenerales'
import Support from 'modules/Support/Support'


function AuthStack() {
    return (
        <Routes>
            <Route path='*' element={<Login />} />
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/confirm-password' element={<ConfirmPassword />} />
            <Route path='/forget-password' element={<ForgetPassword />} />

            <Route path='/legal/en/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/legal/fr/politique-de-confidentialite' element={<PolitiqueConfidentialite />} />

            <Route path='/legal/en/conditions-of-use' element={<TermsConditions />} />
            <Route path='/legal/fr/conditions-generales/' element={<ConditionsGenerales />} />
            
            <Route path="support" element={<Support />} />
        </Routes>
    )
}

export default AuthStack